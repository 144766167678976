var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-data-table", {
    staticClass: "text-truncate",
    attrs: {
      headers: _vm.headers,
      items: _vm.queryPreviewData.tableData,
      "sort-desc": [true, true],
      "hide-default-footer": "",
    },
    scopedSlots: _vm._u([
      {
        key: "top",
        fn: function () {
          return [
            _c(
              "v-banner",
              {
                staticClass: "mb-5",
                attrs: { "single-line": "" },
                scopedSlots: _vm._u([
                  {
                    key: "actions",
                    fn: function () {
                      return [
                        _c(
                          "v-btn",
                          {
                            staticClass: "font-weight-bold ml-1 caption",
                            attrs: { color: "primary", loading: _vm.loading },
                            on: { click: _vm.saveFullResults },
                          },
                          [
                            _c(
                              "v-icon",
                              { staticClass: "mr-1", attrs: { small: "" } },
                              [_vm._v("save")]
                            ),
                            _vm._v(" Save Full Results "),
                          ],
                          1
                        ),
                        _c("GetTableData", {
                          attrs: { sqlText: _vm.queryPreviewData.sql },
                        }),
                        _c(
                          "v-dialog",
                          {
                            attrs: { "max-width": "500" },
                            scopedSlots: _vm._u([
                              {
                                key: "activator",
                                fn: function ({ on }) {
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g({ attrs: { text: "" } }, on),
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "d-flex align-center",
                                          },
                                          [
                                            _c(
                                              "v-icon",
                                              {
                                                staticClass: "mr-1",
                                                attrs: {
                                                  small: "",
                                                  color: "secondary",
                                                  dark: "",
                                                },
                                              },
                                              [_vm._v("mdi-eye-outline")]
                                            ),
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "font-weight-bold caption secondary--text",
                                              },
                                              [_vm._v("View Query")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                              },
                            ]),
                            model: {
                              value: _vm.sqlDialog,
                              callback: function ($$v) {
                                _vm.sqlDialog = $$v
                              },
                              expression: "sqlDialog",
                            },
                          },
                          [
                            _c(
                              "v-card",
                              [
                                _c("v-card-title", [
                                  _c(
                                    "div",
                                    { staticClass: "secondary--text" },
                                    [
                                      _c("v-icon", { staticClass: "mr-1" }, [
                                        _vm._v("mdi-script-text-outline"),
                                      ]),
                                      _vm._v(" SQL Text "),
                                    ],
                                    1
                                  ),
                                ]),
                                _c("v-divider", { staticClass: "mb-1" }),
                                _c(
                                  "v-card-text",
                                  [
                                    _c("CodeHighlight", {
                                      attrs: {
                                        language: "sql",
                                        code: _vm.queryPreviewData.sql,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-card-actions",
                                  [
                                    _c("v-spacer"),
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: { color: "secondary", text: "" },
                                        on: {
                                          click: function ($event) {
                                            _vm.sqlDialog = false
                                          },
                                        },
                                      },
                                      [_vm._v("Close")]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              },
              [
                _c(
                  "v-avatar",
                  {
                    attrs: { slot: "icon", color: "white", size: "60" },
                    slot: "icon",
                  },
                  [
                    _c(
                      "v-icon",
                      { attrs: { "x-large": "", icon: "info", color: "info" } },
                      [_vm._v("info")]
                    ),
                  ],
                  1
                ),
                _c(
                  "span",
                  { staticClass: "info--text subtitle-2 font-weight-medium" },
                  [
                    _vm._v(
                      'This is a preview only, click on "SAVE FULL RESULTS" to get the full data.'
                    ),
                  ]
                ),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "no-data",
        fn: function () {
          return [
            _c(
              "v-alert",
              {
                staticClass: "mt-3",
                attrs: { text: "", value: true, color: "info", icon: "info" },
              },
              [_vm._v("No results for this query.")]
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }